import axios from 'axios';
import React, { useContext, useState } from 'react'

const AppContext = React.createContext();

const API_URL = process.env.REACT_APP_API_URL || "https://run.salesblink.io/api/meet/slug"
const SLUG = window.location.href.split("/")[window.location.href.split("/").length-1] || window.location.hostname;

export function useAppData() {
    return useContext(AppContext);
}

export function AppProvider({ children }) {
    const [apiData, setApiData] = useState(null)
    const [error, setError] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)
    const [dates, setDates] = useState([])
    const [availableTimes, setAvailableTimes] = useState(null)

    async function fetchData(timezone) {
        try {
            let { data } = await axios.get(API_URL+`/${SLUG}&timezone=${timezone?encodeURIComponent(timezone):""}`)
            setApiData({...data.data, whitelabelCalendar: data.whitelabelCalendar});
            setDates(data.dates)
            setAvailableTimes(data.availableTimes)
        } catch(err) {
            if(err.response.status === 429) setError(err.response.data)
            else if(err.response.data.title) setError(err.response.data.title)
        }
    }

    return (
        <AppContext.Provider value={{ apiData, fetchData, dates, availableTimes, error, setError, errorDescription, setErrorDescription }}>
            {children}
        </AppContext.Provider>
    )
}