import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import Dialog from './Components/Dialog';
import { AppProvider } from './Context/AppContext';

function App() {
  return (
    <AppProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path='/*' element={<>
              <Dialog />
            </>} />
          </Routes>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
